<template>
	<div class="passenger-flow-container">
		<jy-query ref="searchForm" :model="searchForm" label-width="90px">
			<jy-query-item prop="plateNum" label="车牌号:" v-if="dataType == 1">
				<el-input placeholder="请输入" v-model="searchForm.plateNum"></el-input>
			</jy-query-item>
			<jy-query-item label="所属机构:" prop="orgName">
				<div @click="selectInstitutions">
					<el-input placeholder="请选择" v-model="searchForm.orgName" readonly></el-input>
				</div>
			</jy-query-item>
			<jy-query-item prop="vin" label="VIN码:" v-if="dataType == 1">
				<el-input placeholder="请输入" v-model="searchForm.vin"></el-input>
			</jy-query-item>
			<jy-query-item prop="routeName" label="线路名称:" v-if="dataType == 2">
				<el-input placeholder="请输入" v-model="searchForm.routeName"></el-input>
			</jy-query-item>
			<jy-query-item prop="time" label="数据时间:">
				<el-date-picker
					v-model="searchForm.time"
					type="daterange"
					placeholder="请选择"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd"
					:picker-options="pickOptions"
					:clearable="false"
				>
				</el-date-picker>
				<!-- :picker-options="pickOptions" -->
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="searchHandle" v-if="btnexist('scheduleStatisticalAnalysisPassengerFlowList') || true"
					>查询</el-button
				>
				<el-button
					type="primary"
					plain
					@click="resetForm('searchForm')"
					v-if="btnexist('scheduleStatisticalAnalysisPassengerFlowReset') || true"
					>重置</el-button
				>
			</template>
			<!-- <template slot="operate">
				<el-button type="primary">导出</el-button>
			</template> -->
		</jy-query>
		<div class="table-head">
			<el-radio-group v-model="dataType">
				<el-radio-button label="1">单车客流</el-radio-button>
				<el-radio-button label="2">线路客流</el-radio-button>
			</el-radio-group>
			<div class="export">
				<el-button type="primary" @click="exportHandle">导出</el-button>
			</div>
		</div>
		<div v-show="isSingleCar">
			<jy-table max-height="595" ref="jyTable" :data="tableList">
				<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
				<jy-table-column prop="plateNum" label="车牌号" min-width="100" fixed="left"></jy-table-column>
				<jy-table-column prop="vin" label="车架号" min-width="180" fixed="left"></jy-table-column>
				<jy-table-column prop="orgName" label="所属机构" min-width="200"></jy-table-column>
				<jy-table-column prop="routeName" label="所属线路" min-width="160"></jy-table-column>
				<jy-table-column prop="total" label="总客流" min-width="100"></jy-table-column>
				<jy-table-column prop="c2206" label="22:00-06:00" min-width="120"></jy-table-column>
				<jy-table-column prop="c07" label="07:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c08" label="08:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c09" label="09:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c10" label="10:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c11" label="11:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c12" label="12:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c13" label="13:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c14" label="14:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c15" label="15:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c16" label="16:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c17" label="17:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c18" label="18:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c19" label="19:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c20" label="20:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c21" label="21:00" min-width="60"></jy-table-column>
				<jy-table-column prop="dataDate" label="数据时间" min-width="200">
					<!-- <template v-slot="{ row }">
						{{ `${row.startDate}-${row.endDate}` }}
					</template> -->
				</jy-table-column>
			</jy-table>
		</div>
		<div v-show="!isSingleCar">
			<jy-table max-height="595" ref="jyTable" :data="tableList">
				<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
				<jy-table-column prop="orgName" label="所属机构" min-width="200"></jy-table-column>
				<jy-table-column prop="routeName" label="所属线路" min-width="160"></jy-table-column>
				<jy-table-column prop="busNum" label="公交车数量" min-width="100"></jy-table-column>
				<jy-table-column prop="total" label="总客流" min-width="100"></jy-table-column>
				<jy-table-column prop="c2206" label="22:00-06:00" min-width="120"></jy-table-column>
				<jy-table-column prop="c07" label="07:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c08" label="08:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c09" label="09:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c10" label="10:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c11" label="11:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c12" label="12:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c13" label="13:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c14" label="14:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c15" label="15:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c16" label="16:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c17" label="17:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c18" label="18:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c19" label="19:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c20" label="20:00" min-width="60"></jy-table-column>
				<jy-table-column prop="c21" label="21:00" min-width="60"></jy-table-column>
				<jy-table-column prop="dataDate" label="数据时间" min-width="200">
					<!-- <template v-slot="{ row }">
						{{ `${row.startDate}-${row.endDate}` }}
					</template> -->
				</jy-table-column>
			</jy-table>
		</div>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="page.pageNum"
			:page-size="page.pageSize"
			:total="total"
		></jy-pagination>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
	</div>
</template>

<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import { btnMixins } from "@/common/js/button.mixin";
import dayjs from "dayjs";

export default {
	data() {
		return {
			searchForm: {
				plateNum: "",
				orgName: "",
				// orgId: "",
				vin: "",
				time: []
			},
			tableList: [],
			page: {
				pageSize: 10,
				pageNum: 1
			},
			total: 0,
			dataType: "1",
			btnMenuId: "scheduleStatisticalAnalysisPassengerFlow",
			pickOptions: {
				onPick({ maxDate, minDate }) {
					const max = dayjs(maxDate);
					const min = dayjs(minDate);
					if (max.subtract(1, "month") > min) {
						this.$message({
							message: "只支持查询一个月的数据",
							type: "warning"
						});
					}
				}
			}
		};
	},
	computed: {
		isSingleCar() {
			return this.dataType === "1";
		}
	},
	watch: {
		isSingleCar() {
			this.page.pageNum = 1;
			this.tableList = [];
			this.getTableList();
		}
	},
	components: {
		institutionsTree
	},
	mixins: [btnMixins],
	created() {
		this.setDefaultSearchTime();
		this.getTableList();
	},
	methods: {
		exportHandle() {
			const [startDate = "", endDate = ""] = this.searchForm.time || [];
			const params = {
				...this.searchForm,
				...this.page,
				startDate,
				endDate
			};
			delete params.time;
			const url = this.isSingleCar
				? `/crrc-palm-bus/passengerStat/exportVehiclePassenger`
				: `/crrc-palm-bus/passengerStat/exportRoutePassenger`;
			this.$http.post(url, params, { baseType: "base", isExcel: true }).then(res => {
				if (res.status == 200) {
					const fileName = this.isSingleCar ? "单车客流信息" : "线路客流信息";
					this.$util.funDownload(res.data, fileName);
				} else {
					this.$message({
						message: "导出失败",
						type: "warning"
					});
				}
			});
		},
		// 默认查询时间
		setDefaultSearchTime() {
			const pDay = dayjs().subtract(1, "day").format("YYYY-MM-DD");
			this.searchForm.time = [pDay, pDay];
		},
		async getTableList() {
			const [startDate = "", endDate = ""] = this.searchForm.time || [];
			if (dayjs(endDate).subtract(1, "month") > dayjs(startDate)) {
				this.$message({
					message: "只支持查询一个月的数据",
					type: "warning"
				});
				return;
			}
			const params = {
				...this.searchForm,
				...this.page,
				startDate,
				endDate
			};
			delete params.time;
			const url = this.isSingleCar
				? `/crrc-palm-bus/passengerStat/getVehiclePassenger`
				: `/crrc-palm-bus/passengerStat/getRoutePassenger`;
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.tableList = res.records || [];
				this.total = res.total || 0;
			});
		},
		searchHandle() {
			this.page.pageNum = 1;
			this.getTableList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			// this.searchForm.orgId = "";
			this.setDefaultSearchTime();
			this.page.pageNum = 1;
			this.getTableList();
		},
		// 所属机构
		addInstitutions(data) {
			console.log(data);
			// this.searchForm.orgId = data.orgId;
			this.searchForm.orgName = data.orgNa;
		},
		// 组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 分页
		handleSizeChange(val) {
			this.page.pageNum = 1;
			this.page.pageSize = val;
			this.getTableList();
		},
		handleCurrentChange(val) {
			this.page.pageNum = val;
			this.getTableList();
		}
	}
};
</script>
<style lang="scss" scoped>
.table-head {
	display: flex;
	align-items: center;
	.export {
		margin-left: auto;
	}
}
</style>
